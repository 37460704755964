<template>
	<div class="partial partial--performance performance" :style="pageStyles">
		<slideshow
			:slideCount="slideCount"
			:sectionLabel="`Performance Dashboard`"
			:subsectionLabel="content.subsectionLabel"
			:dark="content.dark"
			:slideActive="slideActive"
			:progressColor="content.progressColor"
			@slideActive="setSlideActive"
			>
			<template v-slot:body>
				<component :is="content.component" />
			</template>
			<template v-slot:actions>
				<a :href="`${publicPath}pdf/DTCC_Annual_Financial_Statements_2020_and_2019.pdf`" class="btn btn--primary btn--outline">Download Consolidated Financial Statements</a>
			</template>
		</slideshow>
	</div>
</template>

<script>
import Slideshow from '@/components/Slideshow'

// Performance "slides"
const Overview = () => import(/* performance */ '@/views/partials/performance/Overview')
const AlternativeInvestment = () => import(/* performance */ '@/views/partials/performance/AlternativeInvestment')
const AlternativeInvestment2 = () => import(/* performance */ '@/views/partials/performance/AlternativeInvestment2')
const AlternativeInvestment3 = () => import(/* performance */ '@/views/partials/performance/AlternativeInvestment3')
const EquitiesClearing = () => import(/* performance */ '@/views/partials/performance/EquitiesClearing')
const FixedIncome = () => import(/* performance */ '@/views/partials/performance/FixedIncome')
const FixedIncome2 = () => import(/* performance */ '@/views/partials/performance/FixedIncome2')
const GlobalImpact = () => import(/* performance */ '@/views/partials/performance/GlobalImpact')
const GlobalImpact2 = () => import(/* performance */ '@/views/partials/performance/GlobalImpact2')
const InstitutionalTrade = () => import(/* performance */ '@/views/partials/performance/InstitutionalTrade')
const InsuranceRetirement = () => import(/* performance */ '@/views/partials/performance/InsuranceRetirement')
const InsuranceRetirement2 = () => import(/* performance */ '@/views/partials/performance/InsuranceRetirement2')
const SecuritiesProcessed = () => import(/* performance */ '@/views/partials/performance/SecuritiesProcessed')
const SecuritiesSettled = () => import(/* performance */ '@/views/partials/performance/SecuritiesSettled')
const SettlementAsset = () => import(/* performance */ '@/views/partials/performance/SettlementAsset')
const SettlementAsset2 = () => import(/* performance */ '@/views/partials/performance/SettlementAsset2')
const TotalAssets = () => import(/* performance */ '@/views/partials/performance/TotalAssets')
const TotalRevenue = () => import(/* performance */ '@/views/partials/performance/TotalRevenue')

export default {
	name: 'PartialPerformance',
	components: {
		Slideshow,
	},
	data: () => ({
		slideActive: 0,
		publicPath: process.env.BASE_URL
	}),
	computed: {
		slides() {
			return [
				{
					id: 'overview',
					dark: true,
					component: Overview,
					progressColor: `#0096D6`
				},
				{
					id: 'securities-processed',
					dark: true,
					component: SecuritiesProcessed,
					progressColor: `#0096D6`,
					bgImg: 'bg2@2x.jpg'
				},
				{
					id: `securities-settled`,
					bgColor: 'rgba(0,57,86)',
					component: SecuritiesSettled,
					progressColor: `#E3E431`,
					bgImg: 'bg3@2x.jpg'
				},
				{
					id: `total-revenue`,
					dark: true,
					component: TotalRevenue,
					progressColor: `#0096D6`,
				},
				{
					id: `total-assets`,
					bgColor: 'rgba(0,57,86)',
					component: TotalAssets,
					progressColor: `#E3E431`,
				},
				{
					id: `equities-clearing`,
					dark: true,
					component: EquitiesClearing,
					progressColor: `#E85F43`,
				},
				{
					id: `fixed-income`,
					bgColor: 'rgba(185,76,54)',
					component: FixedIncome,
					bgImg: 'bg7@2x.jpg'
				},
				{
					id: `fixed-income-2`,
					bgColor: 'rgba(185,76,54)',
					component: FixedIncome2,
					bgImg: 'bg8@2x.jpg'
				},
				{
					id: `settlement-asset`,
					dark: true,
					component: SettlementAsset,
					progressColor: `#79C043`,
				},
				{
					id: `settlement-asset-2`,
					bgColor: 'rgba(86,146,57)',
					component: SettlementAsset2,
					bgImg: 'bg9@2x.jpg'
				},
				{
					id: `alternative-investment`,
					dark: true,
					component: AlternativeInvestment,
					progressColor: `#F78E1E`,
				},
				{
					id: `alternative-investment-2`,
					dark: true,
					component: AlternativeInvestment2,
					progressColor: `#F78E1E`,
				},
				{
					id: `alternative-investment-3`,
					dark: true,
					component: AlternativeInvestment3,
					progressColor: `#F78E1E`,
				},
				{
					id: `insurance-retirement`,
					bgColor: 'rgb(208,117,30)',
					dark: false,
					component: InsuranceRetirement,
					bgImg: 'bg13@2x.jpg'
				},
				{
					id: `insurance-retirement-2`,
					bgColor: 'rgb(208,117,30)',
					dark: false,
					component: InsuranceRetirement2,
					bgImg: 'bg14@2x.jpg'
				},
				{
					id: `institutional-trade`,
					dark: true,
					component: InstitutionalTrade,
					progressColor: `#00B0AD`,
				},
				{
					id: `global-impact`,
					bgColor: 'rgb(0,57,86)',
					dark: false,
					component: GlobalImpact,
					progressColor: `#0096D6`,
					bgImg: 'bg16@2x.jpg'
				},
				{
					id: `global-impact-2`,
					dark: true,
					component: GlobalImpact2,
					progressColor: `#0096D6`,
					bgImg: 'bg17@2x.jpg'
				},
			]
		},
		slideCount() {
			return this.slides.length
		},
		content() {
			return this.slides[this.slideActive]
		},
		pageStyles() {
			let img
			try {
				img = require('@/assets/performance/'+ this.content.bgImg)
			} catch (error) {
				// fail silently
			}
			return {
				backgroundColor: this.content.bgColor,
				backgroundImage: `url(${img})`
			}
		},
	},
	methods: {
		setSlideActive(active) {
			const slide = this.slides[active]
			this.$router.push({ params: { slide: slide.id }})
		},
	},
	watch: {
		$route: {
			immediate: true,
			handler: function(val) {
				const { slide } = val.params
				const slideIdx = this.slides.findIndex(s => s.id == slide) || 0

				this.slideActive = slideIdx
			},
		},
	},

}
</script>

<style scoped lang="scss">
.performance {
	transition: background-color 150ms linear;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	width: 100%;

	::v-deep &__col {
		@include gutter('margin-bottom', .5);
		display: flex;
		flex-direction: column;
		align-items: center;

		@include media-query-min-width('tablet') {
			display: grid;
		}
	}

	::v-deep &__col--2 {
		grid-template-columns: repeat(1fr);
		width: 100%;

		@include media-query-min-width('tablet') {
			grid-template-columns: repeat(2, 1fr);
			width: auto;
		}
	}

	::v-deep &__col--3 {
		grid-template-columns: repeat(1fr);
		width: 100%;

		@include media-query-min-width('tablet') {
			grid-template-columns: repeat(3, 1fr);
			width: auto;
		}
	}

	::v-deep &__content {
		p {
			@include fontSize(14px);
			line-height: 1.5;

			@include media-query-min-width('tablet') {
				@include fontSize(18px);
			}
		}
	}

	::v-deep &__title {
		@include fontSize(40px);
		@include gutter('margin-bottom', .5);
		@include gutter('padding', .5);
		color: color_('primary', 'text');
		font-family: $font-family-secondary;
		font-weight: bold;
		margin-top: 0;
		text-transform: uppercase;
		line-height: .9;
		text-align: center;


		@include media-query-min-width('tablet') {
			@include fontSize(72px);
			@include gutter('padding', 0);
			text-align: left;
			max-width: 100%;
		}

		br {
			display: none;

			@include media-query-min-width('tablet') {
				display: initial;
			}
		}

		.slideshow--dark & {
			color: color_('text');
		}
	}

	::v-deep &__subtitle {
		@include fontSize(24px);
		@include gutter('margin-bottom', .75);
		font-family: $font-family-secondary;
		letter-spacing: 1px;
		color: color_('primary', 'text');
		text-transform: uppercase;
		margin: 0;
		text-align: center;
		width: 100%;
		display: block;

		@include media-query-min-width('tablet') {
			text-align: left;
		}

		.slideshow--dark & {
			color: color_('text');
		}
	}

}
</style>
