<template>
	<svg xmlns="http://www.w3.org/2000/svg" width="33.414" height="33.414" viewBox="0 0 33.414 33.414">
		<g id="close_icon" data-name="close icon" transform="translate(-1279.293 -63.293)">
			<path id="Path_393" data-name="Path 393" d="M20184-4099l32,32" transform="translate(-18904 4163)" fill="none" :stroke="iconStyles.stroke" stroke-width="2"/>
			<path id="Path_394" data-name="Path 394" d="M20184-4099l32,32" transform="translate(5379 20280) rotate(-90)" fill="none" :stroke="iconStyles.stroke" stroke-width="2"/>
		</g>
	</svg>
</template>

<script>
export default {
	name: 'CloseIcon',
	props: {
		color: {
			type: String,
			default: '#ffffff',
		},
	},
	computed: {
		iconStyles() {
			return {
				stroke: this.color,
			}
		},
	},
}
</script>

<style scoped lang="scss">

</style>
