<template>
	<div class="partial partial--slideshow slideshow" :class="{'slideshow--dark': dark }">
		<div class="slideshow-progress">
			<div class="slideshow-progress__track">
				<div class="slideshow-progress__indicator" :style="progressStyle"></div>
			</div>
		</div>
		<div class="slideshow__header">
			<div class="slideshow__header-section">
				<div class="slideshow__logo">
					<img :src="require(dark ? `@/assets/logo_dtcc--black.svg` : `@/assets/logo_dtcc.svg`)" alt="DTCC Logo" width="98" />
				</div>
				<div class="slideshow__section" v-html="sectionLabel"></div>
				<div class="slideshow__subsection" v-html="subsectionLabel"></div>
			</div>
			<div class="slideshow__header-actions">
				<a href="#" @click.prevent="close">
					<close-icon :color="dark ? `#000000` : `#ffffff`" />
				</a>
			</div>
		</div>
		<div class="slideshow__body">
			<div class="slideshow__nav">
				<div class="slideshow__nav-link slideshow__nav-link--prev" @click="prevSlide"></div>
				<div class="slideshow__nav-link slideshow__nav-link--next" @click="nextSlide"></div>
			</div>
			<div class="slideshow__content">
				<slot name="body">[body here]</slot>
			</div>
		</div>
		<div class="slideshow__footer">
			<div class="slideshow__footer-actions">
				<slot name="actions"></slot>
			</div>
			<div class="slideshow__footer-controller">
				<div class="slideshow-controls">
					<div class="slideshow-controls__pg">{{ slideActive + 1 }} / {{ slideCount }}</div>
					<div class="slideshow-controls__actions">
						<button @click="playToggle()" class="btn-control btn-control--outline" :class="{'btn-control--dark': dark}">
							<img :src="require(isPlaying ? `@/assets/icon_pause.svg` : `@/assets/icon_play.svg`)" />
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import CloseIcon from '@/components/CloseIcon'
import { Flipper, Flipped } from 'vue-flip-toolkit'

export default {
	name: 'PartialSlideshow',
	components: {
		CloseIcon,
		Flipper,
		Flipped,
	},
	props: {
		dark: {
			type: Boolean,
			default: false,
		},
		duration: {
			type: Number,
			default: 15,	// seconds
		},
		sectionLabel: {
			type: String,
			default: ``,
		},
		subsectionLabel: {
			type: String,
			default: ``,
		},
		progressColor: {
			type: String,
			default: `rgba(255, 255, 255, 0.5)`
		},
		slideCount: {
			type: Number,
			default: 0,
		},
		slideActive: {
			type: Number,
			default: 0,
		},
		autoPlay: {
			type: Boolean,
			default: false,
		},
	},
	data: () => ({
		isPlaying: false,
		// slideActive: 0,
		slideTimer: null,
	}),
	computed: {
		progressStyle() {
			return {
				animationName: this.isPlaying ? `` : `paused`,
				animationDuration: `${this.duration * 1000}ms`,
				animationIterationCount: 'infinite',
				backgroundColor: this.progressColor,
			}
		},
	},
	mounted() {
		this.isPlaying = this.autoPlay
		window.addEventListener('keydown', this.keyListener)
	},
	destroyed() {
		window.removeEventListener('keydown', this.keyListener)
	},
	methods: {
		close() {
			this.$router.push({ name: 'dashboard' })
		},
		keyListener(evt) {
			if (evt.key == 'ArrowRight' || evt.keyCode == 39) {
				this.nextSlide()
			}

			if (evt.key == 'ArrowLeft' || evt.keyCode == 37) {
				this.prevSlide()
			}
		},
		playToggle(state) {
			if (state !== undefined) {
				this.isPlaying = state
			} else {
				this.isPlaying = !this.isPlaying
			}
		},
		nextSlide() {
			let next = (this.slideActive + 1)
			if (next >= this.slideCount) {
				next = 0
			// } else {
			// 	this.slideActive = next
			}

			this.$emit('slideActive', next)
			this.playToggle(false)
		},
		prevSlide() {
			let prev = (this.slideActive - 1)
			if (prev < 0) {
				prev = this.slideCount - 1
			// } else {
			// 	this.slideActive = prev
			}

			this.$emit('slideActive', prev)
			this.playToggle(false)
		},
	},
	watch: {
		isPlaying: {
			immediate: true,
			handler: function(state) {
				if (state) {
					const duration = (this.duration * 1000)
					this.slideTimer = setInterval(this.nextSlide, duration);
				} else {
					this.slideTimer = clearInterval(this.slideTimer)
				}

				this.$emit('isPlaying', state)
			},
		},
	},
}
</script>

<style scoped lang="scss">
$transition: 150ms linear;

.slideshow {
	$slideshow: &;
	align-items: center;
	backdrop-filter: blur(2px);
	display: flex;
	flex-direction: column;
	height: 100%;

	&__image {
		height: 350px;
		width: 800px;
	}

	&__header {
		@include gutter('padding');
		@include fontSize(15px);
		align-items: center;
		color: color_('primary', 'text');
		display: flex;
		justify-content: space-between;
		max-width: 96vw;
		width: 100%;

		#{$slideshow}--dark & {
			color: color_('text', 'xdark');
		}

		@include media-query-min-width('desktop') {
			padding-top: 4vh;
		}

		&-section {
			display: flex;
			align-items: center;
		}

		&-actions {
			transition: opacity $transition-1;
			opacity: 1;

			&:hover {
				opacity: .5;
			}

			svg {
				transform: scale(.75);

				@include media-query-min-width('desktop') {
					transform: unset;
				}
			}
		}
	}

	&__logo {
		@include gutter('padding-right', 1.5);
		@include gutter('margin-right', 1.5);
		@include fontSize(15px);
		padding-top: 5px;
		padding-bottom: 5px;
		border-right: 2px solid rgba(color_('primary', 'text'), .5);
		display: none;
		transition: border $transition;

		#{$slideshow}--dark & {
			border-right-color: color_('text', 'dark');
		}

		@include media-query-min-width('tablet') {
			display: block;
		}
	}

	&__section {
		color: color_('primary', 'text');
		font-weight: 900;
		margin-right: 10px;
		transition: color $transition;

		#{$slideshow}--dark & {
			color: color_('text', 'xdark');
		}
	}

	&__subsection {
		color: rgba(color_('primary', 'text'), .5);
		transition: color $transition;

		#{$slideshow}--dark & {
			color: rgba(color_('text', 'xdark'), .5);
		}
	}

	&__body {
		@include gutter('padding');
		flex: 1 1 0;
		align-items: flex-start;
    	display: flex;
		max-width: 96vw;
		overflow-y: auto;
		position: relative;
		width: 100%;

		@include media-query-min-width('tablet') {
			align-items: center;
			min-height: 420px;
		}
	}

	&__content {
		align-items: center;
		color: color_('primary', 'text');
		display: flex;
		justify-content: center;
		transition: color $transition;
		width: 100%;

		#{$slideshow}--dark & {
			color: color_('text');
		}

		@include media-query-min-width('tablet') {
			@include layout();
			margin-top: auto;
			margin-bottom: auto;
		}
	}

	&__nav {
		display: flex;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		position: absolute;
		z-index: 99;

		&-link {
			flex: 1 1 0;
			height: 100%;

			&--prev {
				// cursor: w-resize;
				cursor: url('~@/assets/icon__arrow-left--light.png'), auto;
				#{$slideshow}--dark & {
					cursor: url('~@/assets/icon__arrow-left--dark.png'), auto;
				}
			}

			&--next {
				cursor: url('~@/assets/icon__arrow-right--light.png'), auto;
				#{$slideshow}--dark & {
					cursor: url('~@/assets/icon__arrow-right--dark.png'), auto;
				}
			}
		}
	}

	&__footer {
		@include gutter('padding-left');
		@include gutter('padding-right');
		@include gutter('padding-bottom', 1.5);
		align-items: center;
		display: flex;
		justify-content: space-between;
		max-width: 96vw;
		width: 100%;

		position: fixed;
		bottom: 0;
		z-index: 100;

		@include media-query-max-width('tablet') {
			color: color_('text');
		}
	}
}

.slideshow-controls {
	@include fontSize(14px);
	align-items: center;
	color: #fff;
	display: flex;
	transition: color $transition;

	.slideshow--dark & {
		color: color_('text', 'xdark');
	}

	&__actions {
		margin-left: 1rem;
	}
}

.slideshow-progress {
	height: 10px;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	width: 100%;

	&__track {
		height: 100%;
	}

	&__indicator {
		background-color: red;
		height: 100%;
		transform: scaleX(0);
		animation-name: progressIndicator;
		// animation-fill-mode: forwards;
		animation-timing-function: linear;
		transform-origin: left center;
	}
}

.slideshow--pandemic {
	.slideshow__section {
		@include media-query-max-width('phone') {
			color: color_('text');
		}
	}

	.slideshow__subsection {
		@include media-query-max-width('phone') {
			color: rgba(color_('text'), .5);
		}
	}

	.slideshow-controls {
		@include media-query-max-width('phone') {
			color: color_('text', 'xdark');
		}
	}

	.btn-control--outline {
		@include media-query-max-width('phone') {
			border-color: color_('text', 'xdark');
		}

		img {
			@include media-query-max-width('phone') {
				filter: invert(1);
			}
		}
	}

	.slideshow__header-actions {
		@include media-query-max-width('phone') {
			filter: invert(1);
		}
	}

	&:before {
		@include media-query-max-width('phone') {
			background-color: rgba(255,255,255,.85);
			content: "";
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			left: 0;
			z-index: -1;
		}
	}




}

@keyframes progressIndicator {
	0% {
		transform: scaleX(0);
	}

	100% {
		transform: scaleX(1);
	}
}
</style>
